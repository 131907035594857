<template>
  <div>
    <b-row>
      <b-col>
        <h1>Export Case to Word Report</h1>
      </b-col>
    </b-row>
    <hr>

    <div v-if="SelectDocumentReportTemplate">
      <b-row>
        <b-col>
          <h2>Select Document Report Template</h2>
        </b-col>
      </b-row>
      <b-row class="pb-1">
        <b-col md="3">
          <v-select
            id="documentReportTemplate"
            v-model="DocumentReportTemplate"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="documentReportTemplateOptions"
            placeholder=""
            name="documentReportTemplate"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-button
            type="button"
            variant="primary"
            class="w-100"
            @click="useThisDocumentReportTemplate()"
          >
            <span>Use This Document Report Template</span>
          </b-button>
        </b-col>
      </b-row>
    </div>

    <div v-if="SelectCaseActionsToInclude">
      <b-row>
        <b-col>
          <h2>Select Case Actions To Include</h2>
        </b-col>
      </b-row>
      <b-row class="pb-1">
        <!-- Table -->
        <b-col class="min-height-10 rounded-sm white-bg-container">
          <b-row>
            <b-col md="1">
              <b-form-checkbox
                id="checkAll"
                v-model="CheckAll"
                name="checkAll"
              >
                All
              </b-form-checkbox>
            </b-col>
            <b-col md="4">
              Action
            </b-col>
            <b-col md="2">
              Scheduled Date
            </b-col>
            <b-col md="4">
              Case Action Status
            </b-col>
          </b-row>
          <b-row
            v-for="(item, index) of ActionsToInclude"
            :key="index"
          >
            <b-col>
              <b-row class="pt-1">
                <b-col md="1">
                  <span>
                    <b-form-checkbox
                      :id="'checkAll' + item.ActionID"
                      v-model="item.checked"
                      name="checkAll"
                    />
                  </span>
                </b-col>
                <b-col md="4">
                  <router-link :to="'/cases/edit-action/' + item.ActionID">
                    <span class="action-title">{{ item.ActionFileNumber + (item.Title ? " - " + item.Title : "") }}</span>
                  </router-link>
                </b-col>
                <b-col md="2">
                  <span>{{ item.ScheduledDate | dateFormat }}</span>
                </b-col>
                <b-col md="4">
                  <span>{{ item.Status + (item.ApprovedByManagerID ? " (Approved)" : " (Not Approved)") }}</span>
                </b-col>

              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-button
            type="button"
            variant="primary"
            class="w-100"
            @click="useTheseCaseActions()"
          >
            <span>Use These Case Actions</span>
          </b-button>
        </b-col>
      </b-row>
    </div>

    <div v-if="SelectMedia">
      <b-row class="pb-1">
        <b-col>
          <h2>Select Media</h2>
        </b-col>
        <b-col>
          <b-button
            type="button"
            variant="primary"
            @click="selectAllImages()"
          >
            <span>Select All Images</span>
          </b-button>
        </b-col>
        <b-col>
          <b-button
            type="button"
            variant="primary"
            @click="unSelectAllImages()"
          >
            <span>Unselect All Images</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-for="item in Videos"
          :key="item.FileID"
          md="3"
        >
          <div
            class="boarder-content"
            :class="{'selected-img' : (MediaIDs.indexOf(item.FileID) !== -1)}"
          >
            <div
              class="video-container cursor-pointer"
              @click="selectedVideo(item)"
            >
              <div class="video-content">
                <img :src="item.URL">
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-button
            type="button"
            variant="primary"
            class="w-100"
            :disabled="generateReportActive"
            @click="generateReport()"
          >
            <span>Generate Report</span>
          </b-button>
        </b-col>
      </b-row>
    </div>

    <div v-if="DownloadCaseReport">
      <b-row>
        <b-col>
          <span>Download Case Report</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-button
            type="button"
            variant="primary"
            class="w-100"
            @click="downloadReport()"
          >
            <span>Download Report for Case</span>
          </b-button>
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<script>
import vSelect from "vue-select";
import {BButton, BCol, BFormCheckbox, BRow} from "bootstrap-vue";
import APIService from "@core/utils/APIService";

const apiService = new APIService();

export default {
  components: {
    vSelect,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
  },
  filters: {
    dateFormat(val) {
      if (val) {
        const date = new Date(val);
        const options = {year: 'numeric', month: '2-digit', day: '2-digit'}
        if (date) return date.toLocaleDateString("en-US", options);
      } else {
        return null;
      }
    },
  },
  data() {
    return {
      CaseID: "",
      DocumentReportTemplate: "",
      documentReportTemplateOptions: [],
      ActionsToInclude: [],
      CheckAll: true,

      Videos: [],

      SelectDocumentReportTemplate: true,
      SelectCaseActionsToInclude: false,
      SelectMedia: false,
      DownloadCaseReport: false,
      MediaIDs: [],
      ActionIDs: [],
      FileName: "",

      FileForDownload: null,

      generateReportActive: false,
    }
  },
  watch: {
    CheckAll(val) {
      this.ActionsToInclude = this.ActionsToInclude.map(item => {
        return {...item, "checked": val}
      })
    },
  },
  created() {
    this.CaseID = this.$route.params.caseId;
    this.getTemplates();
    this.loadActionLogs();
  },
  methods: {
    getTemplates() {
      apiService
          .get("template/case-report-templates")
          .then(res => {
            this.documentReportTemplateOptions = res.data.map(i => ({title: i.FileName, value: i.FileID}))
          })
    },

    loadActionLogs() {
      apiService
          .get("actions/case/" + this.CaseID)
          .then((res) => {
            const sortResult = res.data.map(item => {
              return {...item, checked: true}
            });
            this.ActionsToInclude = sortResult.sort((a, b) => a.ScheduledDate > b.ScheduledDate ? 1 : -1)
          });
    },

    useThisDocumentReportTemplate() {
      this.SelectDocumentReportTemplate = false;
      this.SelectCaseActionsToInclude = true;
    },

    useTheseCaseActions() {
      this.SelectCaseActionsToInclude = false;
      this.SelectMedia = true;
      this.ActionsToInclude.map(item => {
        if(item.checked) {
          this.ActionIDs.push(item.ActionID)
        }
      })
      const postData = {
        ActionIDs: this.ActionIDs,
      }
      apiService
          .post("action/activity/media", postData)
          .then(res => {
            this.Videos = res.data
          })
    },

    generateReport() {
      this.generateReportActive = true;
      const postData = {
        "CaseID": this.CaseID,
        "CaseReportFileID": this.DocumentReportTemplate.value,
        "ActionIDs": this.ActionIDs,
        "MediaIDs": this.MediaIDs
      }
      apiService
          .post("template/case-report-template/parsed", postData)
          .then(res => {
            this.generateReportActive = false;
            this.FileForDownload = res.data.FilePath;
            this.FileName = res.data.FileName;
            this.SelectMedia = false;
            this.DownloadCaseReport = true;
          })
    },

    selectedVideo(item) {
      const index = this.MediaIDs.indexOf(item.FileID);
      if (index === -1) {
        this.MediaIDs.push(item.FileID);
      } else {
        this.MediaIDs.splice(index, 1);
      }
    },

    selectAllImages() {
      this.unSelectAllImages();
      this.Videos.map(item => {
        this.MediaIDs.push(item.FileID);
      })
    },

    unSelectAllImages() {
      this.MediaIDs = [];
    },

    downloadReport() {
      const link = document.createElement('a');
      link.href = this.FileForDownload;
      link.setAttribute('download', this.FileName);
      document.body.appendChild(link);
      link.click();
    },
  }
}
</script>

<style scoped>
.video-container img {
  width: inherit;
  height: inherit;
}
.video-container {
  display: inline-block;
  background-color: grey;
  height: 9rem;
  width: 16rem;
  position: relative;
}
.video-content {
  display: inline-block;
  background-color: grey;
  height: 9rem;
  width: 16rem;
}
.selected-img {
  border: 3px solid rgba(85, 255, 85, 1);
}
.boarder-content {
  width: calc(16rem + 12px);
  height: calc(9rem + 12px);
  margin-bottom: 2rem;
  padding: 3px;
}
.action-title:hover {
  text-overflow: clip;
  white-space: normal;
}
.action-title {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>